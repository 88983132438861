import { Typography, Grid, IconButton, Stack, Alert } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useStyles } from "../../../../Styles";
import FlashLogo from "../../../../assests/Images/logoY.png";
import PrivacyPDF from "../../../../assests/pdf/LLC.pdf"
import LicenseImg from "../../../../assests/pdf/Business_License.jpg";
import LegalNotice from "../../../../assests/pdf/LNotice.pdf";
import License from '../../../../assests/pdf/N_Business_License_202411.pdf';
import AppButton from "../../../../components/AppButton";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Languagemodel } from "../../../../Slice/translateSlice";
// import MobileDrawer from "../MobileDrawer";
import axios from "axios";
import Flag1 from "../../../../assests/Images/flag1.png"
import Flag2 from "../../../../assests/Images/flag3.png"
import Flag3 from "../../../../assests/Images/flag4.png"
import EN from "../../../../assests/pdf/WhitepaperFlashTechnologies.pdf";
import FR from "../../../../assests/pdf/WhitePaperFR.pdf";

const Footer = () => {
  const languageState = useSelector((state) => state.translate.mode)

  const classes = useStyles();

  const list1 = [
    {
      url: "Our Services",
      link: "services",
    },
    {
      url: "Roadmap",
      link: "roadmap",
    },
    {
      url: "App",
      link: "transfer",
    },
    {
      url: "Tokenomics",
      link: "tokenomics",
    },

    {
      url: "NFT",
      link: "nft",
    },
    {
      url: "Partners",
      link: "partners",
    },

    {
      url: "Team",
      link: "team",
    },
    {
      url: "FAQ",
      link: "faq",
    },
    {
      url: "Contact",
      link: "Contact",
    },
  ];
  const list2 = [
    {
      url: "Whitepaper",
      link: "whitepaper",
    },
    {
      url: "Audit",
      link: "tokenomics",
    },
    // {
    //   url: "Become a Partner",
    // },
  ];
  const list3 = [
    {
      url: "Privacy Policy",
      link: "/privacy_policy.html" //PrivacyPDF
    },
    {
      url: "Legal Notice",
      link: LegalNotice
    },
    {
      url: "License",
      link: License
    },
  ];
  const lang = useSelector(Languagemodel)
  const LanguageState = useSelector((state) => state.translate.mode)
  const [messageStatus, SetmessageStatus] = React.useState(<></>);
  const [emailAddress, setEmailAddress] = React.useState('');
  const subscribeHandler = async () => {
    if (!emailAddress) {
      SetmessageStatus(
        <Alert
          variant="filled"
          severity="error"
          style={{
            margin: "auto",
            width: "100%",
            position: "absolute",
            zIndex: "999999999",
            height: "50px",
            display: "flex",
            visibility: 'initial',
          }}
          className="AlertSetting"
        >
          Email is required!
        </Alert>
      );
      setTimeout(() => {
        SetmessageStatus(<></>);
      }, 4000);
      return false;
    }
    await axios
      .post("/sendgrid/subscribe.php", { emailAddress })
      .then((res) => {
        SetmessageStatus(
          <Alert
            variant="filled"
            severity="success"
            style={{
              margin: "auto",
              width: "100%",
              visibility: 'initial',
              position: "absolute",
              zIndex: "999999999",
              height: "50px",
              display: "flex",
            }}
            className="AlertSetting"
          >
            Your message has been sent!
          </Alert>
        );
        setTimeout(() => {
          SetmessageStatus(<></>);
        }, 4000);
      })
      .catch((err) => {
        SetmessageStatus(
          <Alert
            variant="filled"
            severity="error"
            style={{
              margin: "auto",
              width: "100%",
              visibility: 'initial',
              position: "absolute",
              zIndex: "999999999",
              height: "50px",
              display: "flex",
            }}
            className="AlertSetting"
          >
            Error sending message!
          </Alert>
        );
        setTimeout(() => {
          SetmessageStatus(<></>);
        }, 4000);
      });
  }
  return (
    <>
      <Stack
        sx={{
          width: "300px",
          position: "fixed",
          top: "90%",
          zIndex: 9999999,
          right: "10px",
        }}
        spacing={2}
      >
        {/* 11111 */}
        {messageStatus}
      </Stack>
      <Grid
        container
        sx={{
          height: { lg: "450px", xs: "auto" },
          display: "flex",
          justifyContent: "center",
          color: "white",
          maxWidth: "100%",
          width: { lg: "1400px", xs: "100%" },
          padding: { lg: "0px 20px", xs: " 0px 0px" },
          margin: "auto",
          transform: { lg: "scale(0.8)", xs: "scale(none)" },
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            // alignItems: "center",
            flexDirection: { lg: "row", xs: "column" },
            mt: { lg: "50px", xs: "10px" },
            padding: { lg: "0", xs: "0px 30px" },
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={11}
            md={4}
            sx={{ padding: { lg: "0px 100px 0 0", xs: "0px 0px 0 0px" } }}
          >
            <Box
              sx={{
                mt: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: 'center',
              }}
            >
              <Box>
                <img src={FlashLogo} />
              </Box>

              <Typography
                sx={{
                  ml: "10px",
                  fontFamily: "'Manrope' !important",
                  fontSize: "22px !important",
                  fontWeight: "800 !important",
                  lineHeight: "30px !important",
                  marginBottom: '4px'
                }}
              >
                {lang["Flash Technologies"]}
              </Typography>
            </Box>
            <Typography
              sx={{
                marginTop: "37px",
                fontFamily: "'Manrope' !important",
                fontSize: "16px !important",
                fontWeight: "400 !important",
                lineHeight: "170% !important",
                color: "#85898F",
                display: 'flex',
                flexDirection: 'row',
                gap: '10px'
              }}
            >
              <Box>
                <img src={Flag3} />
              </Box>
              {lang["Flash Technologies : Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, U.A.E."]}
            </Typography>
            <Typography
              sx={{
                marginTop: "37px",
                fontFamily: "'Manrope' !important",
                fontSize: "16px !important",
                fontWeight: "400 !important",
                lineHeight: "170% !important",
                color: "#85898F",
                display: 'flex',
                flexDirection: 'row',
                gap: '10px'
              }}
            >
              <Box>
                <img src={Flag1} />
              </Box>
              {lang["Flash Technologies : 66 Avenue Des Champs Elysées,  75008, Paris."]}
            </Typography>
            <Typography
              sx={{
                marginTop: "37px",
                fontFamily: "'Manrope' !important",
                fontSize: "16px !important",
                fontWeight: "400 !important",
                lineHeight: "170% !important",
                color: "#85898F",
                display: 'flex',
                flexDirection: 'row',
                gap: '10px'
              }}
            >
              <Box>
                <img src={Flag2} />
              </Box>
              {lang["Flash Technologies : Abidjan-Cocody, Les II Plateaux, 7ème Tranche, Abidjan."]}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              // alignItems: "center",
              flexDirection: { lg: "row", xs: "column" },
              // justifyContent: "center",
            }}
          >
            <Grid
              item
              md={4}
              xs={12}
              sx={{
                display: "flex",

                alignItems: "start",
                flexDirection: "column",
                // justifyContent: "center",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    marginTop: { lg: "0px", xs: "20px" },
                    fontFamily: "'Manrope' !important",
                    fontSize: "18px !important",
                    fontWeight: "600 !important",
                    lineHeight: "160% !important",
                  }}
                >
                  {lang["Pages"]}
                </Typography>
                {list1.map((i) => {
                  return (
                    <>
                      <Box>
                        <Box
                          style={{
                            textDecoration: "none",
                            color: "#85898F",
                          }}
                        >
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontFamily: "'Manrope' !important",
                              fontSize: "16px !important",
                              fontWeight: "400 !important",
                              lineHeight: "170% !important",
                              color: "#85898F",
                            }}
                          >
                            <div className="menu-link" href={i.link} onClick={() => setTimeout(() => {
                              if (i.url === "Whitepaper") {
                                window.open(languageState == "fr" ? FR : EN);
                                // document.getElementById(i.link).scrollIntoView();
                                // window.scrollBy(0, -100);
                              }
                              else if (i.url === "Roadmap") {
                                document.getElementById(i.link).scrollIntoView();
                                window.scrollBy(0, -55);
                              }
                              else {
                                document.getElementById(i.link).scrollIntoView();
                                window.scrollBy(0, -55);
                              }
                            }, 20)}>
                              {lang[i.url]}
                            </div>
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  );
                })}
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={{
                display: "flex",

                alignItems: "start",
                flexDirection: "column",
                // justifyContent: "center",
              }}
            >
              <Box>
                {/* <img src={FlashLogo} /> */}

                <Typography
                  sx={{
                    marginTop: { lg: "0px", xs: "20px" },
                    fontFamily: "'Manrope' !important",
                    fontSize: "18px !important",
                    fontWeight: "600 !important",
                    lineHeight: "160% !important",
                  }}
                >
                  {lang["Documents"]}
                </Typography>
                {list2.map((i) => {
                  return (
                    <>
                      <Box>
                        <Box
                          style={{
                            textDecoration: "none",
                            color: "#85898F",
                          }}
                        >
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontFamily: "'Manrope' !important",
                              fontSize: "16px !important",
                              fontWeight: "400 !important",
                              lineHeight: "170% !important",
                              color: "#85898F",
                            }}
                          >
                            {i.url === 'Become a Partner' ?
                              <a className="menu-link" id="become_a_partner1" target="_blank" rel="noreferrer"
                                href={`${LanguageState == 'fr' ? 'https://tcjpmvkkcyv.typeform.com/to/ILnGtfxh' : 'https://tcjpmvkkcyv.typeform.com/to/De146S9S'}`}
                              >
                                {lang[i.url]}
                              </a> :
                              <div className="menu-link" onClick={() => setTimeout(() => {
                                if (i.url === "Whitepaper") {
                                  window.open(languageState == "fr" ? FR : EN);
                                  // document.getElementById(i.link).scrollIntoView();
                                  // window.scrollBy(0, -100);
                                }
                                else if (i.url === "Roadmap") {
                                  document.getElementById(i.link).scrollIntoView();
                                  window.scrollTo(0, 0);
                                }
                                else {
                                  document.getElementById(i.link).scrollIntoView();
                                  window.scrollBy(0, -55)
                                }
                              }, 20)}
                              >
                                {lang[i.url]}
                              </div>
                            }
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  );
                })}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                // justifyContent: "center",
              }}
            >
              <Box>
                {/* <img src={FlashLogo} /> */}

                <Typography
                  sx={{
                    marginTop: { lg: "0px", xs: "20px" },
                    fontFamily: "'Manrope' !important",
                    fontSize: "18px !important",
                    fontWeight: "600 !important",
                    lineHeight: "160% !important",
                  }}
                >
                  {lang["Legal"]}
                </Typography>
                {list3.map((i, index) => {
                  return (
                    <>
                      <Box>
                        <Box
                          style={{
                            textDecoration: "none",
                            color: "#85898F",
                          }}
                        >
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontFamily: "'Manrope' !important",
                              fontSize: "16px !important",
                              fontWeight: "400 !important",
                              lineHeight: "170% !important",
                              color: "#85898F",
                            }}
                          >
                            {i.url === "License" ? <a className="menu-link" target="_blank" rel="noreferrer"
                              href={i.link}> {lang[i.url]}</a> :
                              <a href={i.link} className="menu-link" target="_blank" rel="noreferrer">
                                {lang[i.url]}
                              </a>

                            }
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  );
                })}
              </Box>
            </Grid>
          </Grid>{" "}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              m: "20px 0px",
              display: "flex",
              // alignItems: { xs: "start" },
              flexDirection: "column",
              padding: { lg: "0px 20px 0 80px", xs: "0px 0px 0px 0px" },
            }}
          >
            <Typography
              //  align="start"
              sx={{
                fontFamily: "'Manrope' !important",
                fontSize: "18px !important",
                fontWeight: "600 !important",
                lineHeight: "160% !important",
              }}
            >
              {lang["Join Our Newsletter"]}
            </Typography>

            <Box
              sx={{
                paddingTop: "20px",
                right: "0px",
                position: "relative",
              }}
            >
              {/* <Typography pl="20px">Your email address</Typography>  */}
              <input
                placeholder={`${lang["Your email address"]}`}
                onChange={(e) => setEmailAddress(e.target.value)}
                style={{
                  width: "100%",
                  height: "52px",
                  borderRadius: "28px",
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                  paddingLeft: "20px",
                  border: "none",
                  outline: "none",
                  color: "#fff",
                }}
              />

              <IconButton
                className="iconbutton1"
                onClick={subscribeHandler}
                sx={{
                  height: "54px",
                  fontSize: { lg: "16px", xs: "12px" },
                  // margin: "0px 5px",
                  fontFamily: "'Manrope' !important",
                  fontWeight: 600,
                  lineHeight: "170%",
                  borderRadius: "61px",
                  color: "#0F0F0E",
                  padding: { lg: "15px 55px", xs: "15px 34px" },
                  // width: "246px",
                  backgroundColor: "#FBBF04",
                  position: "absolute",
                  right: "0px",
                }}
              >
                {lang["Subscribe"]}
              </IconButton>
            </Box>
            <Typography
              sx={{
                marginTop: "21px",
                fontFamily: "'Manrope' !important",
                fontSize: { lg: "16px !important", xs: "10px" },
                fontWeight: "400 !important",
                lineHeight: "170% !important",
                width: { lg: "100%", xs: "220px" },
                color: "#85898F",
              }}
            >
              {lang["Will send you weekly updates for your better finance management."]}
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            borderTop: "1px solid #5e5757",
            marginTop: '20px',
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              marginBottom: { xs: "55px" },
              marginTop: { xs: "10px" }
            }}
          >
            <Typography
              sx={{
                fontFamily: "'Manrope' !important",
                fontSize: { lg: "16px !important", xs: "10px" },
                fontWeight: "400 !important",
                lineHeight: "170% !important",
              }}
            >
              Flash Technologies © 2023 - All Rights Reserved
            </Typography>
          </Box>
        </Grid> */}
      </Grid>
    </>
  );
};

export default Footer;
