export const French = {
  "Flash Technologies L.L.C-FZ": "Flash Technologies L.L.C-FZ",
  "Audited by": "Audité par",
  Flash: "Flash",
  Technologies: "Technologies",
  "Buy Flash": "Acheter Flash",
  "Flash Technologies creates a money transfer ecosystem.":
    "Flash Technologies crée un écosystème de transfert d'argent.",
  "Flash Transfer is the world's first platform that allows you to buy crypto with cash, transfer, or sell cryptocurrencies for cash through our network of merchants, with reasonable fees. Flash Wallet, our non-custodial wallet, has a smart contract that you can use to transfer your crypto in the event of death.  Find the best fees with Flash Dex, our aggregator swap & bridge!":
    "La plateforme Flash Transfer est la première au monde à vous permettre d'acheter des cryptomonnaies avec de l'argent liquide, de transférer ou de vendre des cryptomonnaies contre de l'argent liquide via notre réseau de commerçants, avec des frais raisonnables. Flash Wallet, notre portefeuille non-custodial, dispose d'un contrat intelligent que vous pouvez utiliser pour transférer vos cryptomonnaies en cas de décès. Trouvez les meilleurs frais avec Flash Dex, notre agrégateur de swap et bridge !",
  CONTACT: "CONTACT",
  "Contact Us": "Nous contacter",
  "Whether you have a question about one of our services or want to get involved in our mission, we are ready to help.":
    "Que vous ayez des questions concernant l’un de nos services, que vous souhaitiez vous impliquer  ou vous développer à nos côtés dans notre mission, nous sommes prêts à vous aider. <br/> <br/> Vous recevrez des mises à jour régulièrement en vous abonnant à notre newsletter.",
  "Follow us:": "Suivez-nous:",
  "First Name": "Prénom",
  "Last Name": "Nom de famille",
  "Your Email Address": "Votre adresse électronique",
  "Tell us about your project": "Parlez-nous de votre projet",
  "Send Message": "Envoyer un message",
  "Type Here": "Tapez ici",
  Our: "Notre",
  "CORE TEAM": "CORE TEAM",
  "has worked with": "a travaillé avec",
  "Core Team": "Core Team",
  "Aladdin CEO & Founder": "Aladdin CEO et Fondateur",
  "CEO & Founder of": "CEO et Fondateur de",
  Blocklabchain: "Blocklabchain",
  Hulk: " Hulk Cars",
  "Safi CCO & Co-Founder": "Safi CCO et Co-Fondateur",
  "Les Artisans d’Orient": "Les Artisans d’Orient.",
  "Junior COO": "Junior COO",
  "Communication English & German. Works in":
    "Communication en Anglais et en Allemand. ",
  "Diplomatic Institution": "Travaille dans une institution diplomatique. ",
  "Binance feed creator": "",
  "Mimirk CPRO": "Mimirk CPRO",
  "Chief Public Relations Officer. Works in":
    "Directeur du marketing. Travaille en tant",
  "Public Relations":
    "qu’expert en marketing et en relations publiques.",
  "Dev. Team": "Dev. Team",
  "Frontend Developer": "Frontend",
  "Oleksii Developer": "Oleksii Développeur",
  "Community Manager": "Gestionnaire de communauté",
  "Ernest CTO": "Ernest CTO",
  "Full stack & Blockchain Developer": "Full stack",
  "Jawad Developer": "Jawad Développeur",
  "Full stack Developer": "Développeur Full stack",
  "Ahsan Developer": "Mikhail Développeur",
  "Umair Developer": "Umair Développeur",
  "Usama Developer": "Usama Développeur",
  "Vadim Developer": "Vadim Développeur",
  "Blockchain Developer": "Développeur Blockchain",
  "Alex Developer": "Alex Développeur",
  "Frontend & Blockchain Developer": "Développeur Frontend & Blockchain",
  "Hassam Editor video": "Monteur de vidéo Hassam",
  "Video creation and editor": "Création et montage de vidéos",
  "Runnart Designer": "Runnart Designer",
  "Administrative graphic": "Graphisme administratif",
  "White paper": "Livre blanc",
  "& App logo designer": "et logo de l'application",
  "Eznau Illustrator": "Illustrateur Eznau",
  "Graphic flyers, logos": "Dépliants graphiques, logos",
  "NFT for all products of": "NFT pour tous les produits de",
  "Flash Technologies": "Flash Technologies",
  "Numa Designer UI/UX": "Numa Designer UI/UX",
  "Flash Transfer": "Flash Transfer",
  "Flash Wallet App": "Flash Wallet App",
  "UI/UX designer": "Concepteur UI/UX de",
  "for": '',
  "Products": 'Des produits',
  "ITO Designer": "ITO Designer",
  FAQ: "FAQ",
  "What is Flash technologies?": "Qu’est-ce que Flash Technologies ?",
  "Flash Technologies is the name that represents all Flash projects.":
    "Flash Technologies est le nom qui représente tous les projets Flash.",
  "How can I buy Flash?": "Comment puis-je acheter Flash?",
  "You can buy Flash token directly on":
    "Vous pouvez acheter nos jetons directement sur ",
  "What is Flash Transfer?": "Qu’est-ce que Flash-Transfer ?",
  "Flash Transfer is the main project of Flash Technologies. We will be able to bank people who do not have a bank account or who wish to free themselves from traditional banks. We can exchange cash for crypto via our various partners. Users of this service will be able to make transfers worldwide.":
    "Flash Transfer est le projet principal de Flash Technologies. Nous allons pouvoir bancariser les personnes qui n'ont pas de compte bancaire ou qui souhaitent s'affranchir des banques traditionnelles. Nous pouvons échanger du cash contre des cryptos via nos différents partenaires. Les utilisateurs de ce service pourront effectuer des transferts dans le monde entier.",
  "What are the advantages of buying Flash ?":
    "Quels sont les avantages d’investir dans les jetons de Flash Technologies ?",
  "Your Flash increases in value according to the evolution of the course. When Flash Technologies projects evolve, it has a positive impact on the price.":
    "Votre Flash prend de la valeur en fonction de l'évolution du cours. Lorsque les projets Flash Technologies évoluent, cela a un impact positif sur le prix.",
  "On which site will the token be listed?":
    "Sur quel site les Tokens seront il répertoriés ?",
  "The token will be listed on cex and dex.":
    "Le jeton sera répertorié sur cex et dex.",
  "Why transaction fees?": "Pourquoi des frais de transaction ?",
  "Transaction fees support the project.":
    "Les frais générés par chaque transaction du FTT (Flash3.0 n’ayant pas de frais de transaction) contribuent directement au soutien et au financement du projet Flash Technologies, une démarche essentielle pour la croissance et la pérennité de notre initiative dans l'écosystème des cryptomonnaies et de la blockchain.",
  "Whitepaper EN": "Livre blanc EN",
  Presentation: "Présentation",
  "Using Flash Wallet": "Utilisation de Flash Wallet",
  SERVICES: "SERVICES",
  "(replace busd with ETH)": "(remplacer busd par ETH)",
  "Flash Ecosystem": "Flash Ecosystème",
  "Discover all our services.": "Découvrez tous nos services.",
  "Exchange your cash vs crypto or crypto vs cash. Buy directly cryptocurrency with our approved partners. You can also send your cryptocurrency and the receiver withdraws it in fiat all over the world using a Binance account or using your non custodial wallet ( Flash wallet, Metamask, Trust wallet ...).":
    "Procédez à l'échange de devises traditionnelles contre des cryptomonnaies, ou inversement, en toute simplicité. Acquérez directement des cryptomonnaies via notre réseau de partenaires certifiés et de confiance. Vous avez également la possibilité d'envoyer vos cryptomonnaies à un destinataire qui pourra les retirer sous forme de monnaie fiduciaire (fiat) dans le monde entier. Cette opération peut être réalisée soit via un compte Binance, soit en utilisant un portefeuille numérique non-dépositaire tel que Flash Wallet, Metamask ou Trust Wallet, offrant ainsi flexibilité et commodité dans la gestion de vos actifs numériques.",
  "Flash Dex": "Flash Dex",
  "Flash dex dapps is an all-in-one swap and bridge that allows users to swap their crypto assets from the same blockchain or from one blockchain to another. With the best possible direct market fees.":
    "Flash Dex DApp est un swap et un pont tout-en un qui permet aux utilisateurs d'échanger leurs  crypto-actifs de la même blockchain ou d'une  blockchain à une autre, directement avec les  meilleurs frais possibles du marché.",
  "Flash Technologies has built a fully integrated cryptocurrency wallet that holders and merchants can use to buy, sell, stake, earn rewards and browse on internet. Flash Wallet has a special function that is unique in cryptospace. A very important feature that users will enjoy everywhere in the world.":
    "Flash Technologies a développé un portefeuille de  cryptomonnaie entièrement intégré que les détenteurs et les marchands peuvent utiliser pour acheter, vendre, miser, gagner des récompenses et naviguer sur Internet. \n Flash Wallet possède une fonction spéciale qui est unique et très importante dans le domaine des cryptomonnaies que les utilisateurs pourront profiter partout dans le monde.",
  "Flash Wallet": "Flash Wallet",
  "Dead Wallet": "Flash Dead",
  "The 'dead-wallet' function. This function enables the recovery of cryptos in another wallet in case of loss of seed phrase and in the event of a death of a crypto holder. It is embedded with a smart-contract linked to a timer that automatically triggers the transfer of funds out of the wallet after a period of time has elapsed. With extra security if ever the dead wallet doesn't have enough gas costs.":
    "La fonction «portefeuille mort». Cette fonction permet de récupérer des cryptomonnaies dans un autre portefeuille en cas de perte de la phrase de récupération et/ou en cas de décès du détenteur de la cryptomonnaie. Le portefeuille est équipé d'un contrat intelligent lié à une minuterie qui déclenche le transfert de fonds hors du portefeuille après la fin du temps  défini ; le destinataire doit réclamer la ou les cryptomonnaie(s). Pour utiliser cette fonctionnalité, visitez notre application flash-wallet.com.",
  "Flash Pay": "Flash Pay",
  "FlashPay, escrow agreements can be created quickly by buyer or seller. Once receipt of goods or services is confirmed funds are immediately transferred to the seller via the smart contract. In the event of a dispute, Flash Pay settles the dispute with the evidence received. At no time are the funds held by Flash Pay, everything is decentralized via the smart contract. Flash Pay can only validate or refuse the payment, nothing else.":
    "Flash Pay, les accords d'entiercement peuvent être créés rapidement par l'acheteur ou le vendeur. Une fois la réception des biens ou des services confirmés, les fonds sont immédiatement transférés au vendeur via le contrat intelligent. En cas de litige, Flash Pay règle le litige avec les preuves reçues. À aucun moment, les fonds ne sont détenus par Flash Pay, tout est décentralisé via le smart contract. Flash Pay ne peut que valider ou refuser le paiement, rien d'autre.",
  "Customers and merchants have the opportunity to earn rewards by using the staking protocol. Each month stakers receive a % of our staking pool.":
    "Les clients et les marchands ont la possibilité de gagner des récompenses en utilisant notre  protocole de Staking. Chaque mois, les Stakers reçoivent un %  de notre pool de Staking.",
  "Flash Staking": "Flash Staking",
  "Flash audit will audit smart contracts (tokens, bridges, staking, all types of dapps) and blockchains of our partners/customers on all networks.":
    "L'audit Flash vérifie les contrats intelligents (Tokens, Bridges, Staking, tous types de DApps) et les blockchains de nos partenaires ou clients sur tous les réseaux. Chaque partenaire ou client qui souhaite faire partie de notre écosystème devra passer par notre audit de sécurité pour s'assurer qu'il n'y ait pas de code malveillant.",
  "Each partner/customer who would like to be in our ecosystem will have to go through our security audit to make sure there is no malicious code.":
    "Each partner/customer who would like to be in our ecosystem will have to go through our security audit to make sure there is no malicious code.",
  "Flash Audit": "Flash Audit",
  "Flash Launchpad": "Flash Launchpad",
  "Flash pad is our launchpad for upcoming IDOs and allows crypto projects to create their tokens (Ethereum, BNB Chain, Polygon, Base, Avax, Cronos, Arbitrum, Blast) and raise funds in pre-sales or fair launches.You can also use the airdrop feature and the audit bot to quickly audit tokens.":
    "Flash Launch est notre rampe de lancement pour les IDO(s) à venir et permet aux projets cryptographiques de créer leurs Tokens sur plusieurs blockchains comme (Ethereum, Polygon, Cronos, BNB Smart Chain) et de lever des fonds lors de prévente ou de lancement équitable.",
  "Secured blockchain": "Blockchain sécurisée",
  "Single transaction": "Transaction unique",
  "Deflationary token": "Jeton déflationniste",
  "Replace a Bank Account": "Remplacer un compte bancaire",
  "WE ARE SUPPORTED": "NOUS SOMMES SOUTENUS",
  "Flash Recharge Card": "Carte Flash recharge",
  "Flash Recharge": "Flash recharge",
  "The Flash recharge is a prepaid card that allows users to top up their account in $ BUSD on the Flash wallet without any intermediary.":
    "La carte prépayée Flash Recharge représente une solution innovante pour les utilisateurs désirant alimenter directement leur compte en cryptomonnaie. Cette méthode permet de créditer des actifs numériques tels que l'Ether (ETH), le BNB, le CRO ou encore diverses monnaies stables, directement sur le Flash Wallet. L'avantage majeur de cette carte est l'élimination de toute intervention intermédiaire, offrant ainsi un processus de recharge simplifié et direct.",
  "Flash Recharge allows users to top up their balance on Flash Wallet.":
    "Flash recharge permet aux utilisateurs de créditer leur solde sur Flash Wallet.",
  "The Flash recharge's cards will be printed at the time of purchase for an eco-responsible approach.":
    "Les cartes Flash recharge seront imprimées au moment de l'achat dans le cadre d’une démarche  écoresponsable.",
  "Cash to crypto and vice versa.":
    "De l'argent liquide aux cryptomonnaies et vice-versa.",
  "DOWNLOAD FROM": "TÉLÉCHARGEMENT",
  "Our Services": "Nos Services",
  "App": "App",
  Tokenomics: "Tokenomics",
  Partnership: "Partnership",
  Roadmap: "Feuille De Route",
  NFT: "NFT",
  Team: "Équipe",
  Whitepaper: "Livre Blanc",
  Audit: "Audit",
  "Become a Partner": "Devenir partenaire",
  'Presale': "Presale",
  "Privacy Policy": "Politique de confidentialité",
  "Legal Notice": "Mentions légales",
  License: "License",
  "Flash Technologies : Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, U.A.E.":
    "Flash Technologies : Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubaï, U.A.E.",
  "Flash Technologies : 66 Avenue Des Champs Elysées,  75008, Paris.":
    "Flash Technologies : 66 Avenue Des Champs Elysées,  75008, Paris.",
  "Flash Technologies : Abidjan-Cocody, Les II Plateaux, 7ème Tranche, Abidjan.":
    "Flash Technologies : Abidjan-Cocody, Les II Plateaux, 7ème Tranche, Abidjan.",
  Pages: "Pages",
  Documents: "Documents",
  Legal: "Legal",
  "Join Our Newsletter": "Inscrivez-vous à notre newsletter",
  "Your email address": "Votre adresse électronique",
  Subscribe: "S'abonner à",
  "Will send you weekly updates for your better finance management.":
    "Vous enverra des mises à jour hebdomadaires pour une meilleure gestion de vos finances.",
  Partners: "Partenaires",
  Contact: "Contact",
  Staking: "Staking",
  "Invest in your Future": "Investissez dans votre avenir",
  "Clients can stake Flash or our approved partner's tokens for a certain period of time in order to earn rewards and bonuses":
    "Les clients peuvent Stake les TOKENS de Flash Technologies ou de l’un de nos partenaires du moment pendant une période définie afin de gagner des <b style='color:#fff;'>récompenses</b> et des <b style='color:#fff;'>bonus</b>.  ",
  Stake: "Stake",
  "Stake FLASH and receive rewards.":
    "Misez sur le Flash Token et recevez des récompenses.",
  "Complete Staking Term": "Terme de Staking complet",
  "Users can choose the staking terms and hold their stake for s period of time to earn higher yield bonus returns.":
    "Les utilisateurs peuvent choisir les conditions de Staking et les conserver durant une période  définie dans le but de gagner des bonus de rendement plus élevés.",
  "Pay via Flash and partner tokens":
    "Soyez Payé via les Tokens Flash et les tokens partenaires",
  "After every successful stake, users are rewarded with Flash token as a farming bonus and partners tokens.":
    "Les utilisateurs de Flash-Staking pourront choisir leurs rewards entre les Tokens Flash et les Tokens  partenaires, du moment. ",
  "Listing & Partners": "Listings et partenaires",
  "Seen in the media": "Vu dans les médias",
  "GET YOUR NFT UTILITY": "OBTENEZ VOTRE NFT UTILITAIRE",
  Airdrop: "AIRDROP",
  "Each month receive Flash in your wallet.":
    "Chaque mois, recevez du Flash Token dans votre Wallet.",
  "Holders of the AIRDROP NFTS receive Flash tokens every month. The amount differs depending on the rarity of the NFT. It also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed in the future.":
    "Les NFTS AIRDROP vous permettent de recevoir des  Flash Token tous les mois, le montant varie en fonction de la rareté du NFT. (Bronze, Argent, Or et légendaire) Ils donnent également accès à d'autres avantages, comme notre club VIP avec des calls Nfts et Tokens, l’accès à des whitelists ainsi que des réductions et d'autres avantages qui seront divulgués ultérieurement.",
  "Ranking Increase": "Augmentation du classement",
  "Will quickly increase the privilege ranking.":
    "Augmentera rapidement le classement de vos privilèges.",
  "The NFTS RANKING INCREASE allow you to increase quickly your ranking points on Flash Transfer Website. The earned points depend on the rarity of the NFT. It also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed later.":
    "Les NFTS RANKING INCREASE vous permettent d'augmenter rapidement vos points de classement sur le site de Flash-Transfer, les points gagnés dépendent de la rareté du NFT. (Bronze, Argent, Or et légendaire) Ils donnent également accès à d'autres avantages, comme notre club VIP avec des calls Nfts et Tokens, l’accès à des whitelists ainsi que des réductions et d'autres avantages qui seront divulgués ultérieurement.",
  "Discount on transaction fees": "FEE REDUCTION",
  "Will entitle you to fees on money transfer site transactions.":
    "Vous donnera droit à des frais de transaction  réduit sur le site de Flash-transfer.",
  "The NFTS DISCOUNT ON TRANSACTION FEES provide discounts on transaction fees. The reduction is bigger depending on the rarity of the NFT. It also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed later.":
    "Les NFTS FEE REDUCTION vous offrent des réductions sur les frais de transaction, la réduction est plus importante en fonction de la rareté du NFT. (Bronze, Argent, Or et légendaire) Ils donnent également accès à d'autres avantages, comme notre club VIP avec des calls Nfts et Tokens, l’accès à des whitelists ainsi que des réductions et d'autres avantages qui seront divulgués ultérieurement.",
  "Random effect": "RANDOM",
  "Every 3 months or 6 months the function of the NFT changes.":
    "La fonction du NFT change tous les 3 à 6 mois.",
  "The NFTS RANDOM EFFECT change abilities every 4 months. The benefit is greater depending on the rarity of the NFT. It also gives access to other benefits including the vip club (call nft & tokens), whitelist, discounts and other benefits that will be disclosed later.":
    "Les NFTS RANDOM EFFECT vous permettent d’obtenir un effet aléatoire sur votre NFT tous les 3 à 6 mois environ, le bénéfice est plus important en fonction de la rareté du NFT. (Bronze, Argent, Or et légendaire) Ils donnent également accès à d'autres avantages, comme notre club VIP avec des calls Nfts et Tokens, l’accès à des whitelists ainsi que des réductions et d'autres avantages qui seront divulgués ultérieurement.",
  "Flash Technologies Roadmap": "Feuille de route Flash Technologies",
  "August 2021": "Août 2021",
  "Market study and meeting with professionals for the feasibility of the project.":
    "Étude de marché et rencontre avec des professionnels pour la faisabilité du projet.",
  "September 2021": "Septembre 2021",
  "Definition of the project and creation of the whitepaper.":
    "Définition du projet et création du livre blanc.",
  "October 2021": "Octobre 2021",
  "Creation of the team of developers. Creation of the Flash Token Website.":
    "Création de l'équipe de développeurs. Création du site web Flash Token.",
  "November 2021": "Novembre 2021",
  "Implementation of the Marketing strategy. Creation of the money transfer platform website. Meeting with influencers for the promotion of Flash Token. Launch of the Flash Token on the Bsc.":
    "Mise en œuvre de la stratégie marketing. Création du site internet de la plateforme de transfert d'argent. Rencontre avec des influenceurs pour la promotion du Flash Token. Lancement du Flash Token sur la Bsc.",
  "December 2021": "Décembre 2021",
  "Promotion of the Flash Token by influencers.":
    "Promotion du jeton Flash par des influenceurs.",
  "January 2022": "Janvier 2022",
  "Launch of the 1st NFT collection. Launch of the Flash Token on the Bsc.":
    "Lancement de la 1ère collection NFT. Lancement du Flash Token sur le Bsc.",
  "February 2022": "Février 2022",
  "Release of the beta version of the money transfer. Coming out of the Nft mint platform Deployment.":
    "Sortie de la version bêta du transfert d'argent. Sortie du déploiement de la plateforme Nft mint",
  2022: "2022",
  "Launch of Flash Technologies website":
    "Lancement du site web de Flash Technologies",
  "Unveiling of the first Flash Transfer partners":
    "Dévoilement des premiers partenaires de Flash Transfer",
  "Launch of Flash Audit": "Lancement de l'audit Flash",
  "Vip Club Launch (only for the 200 Nft)":
    "Lancement du Vip Club (uniquement pour les 200 Nft)",
  "Launch of the advertising campaign with influencers + new ambassadors":
    "Lancement de la campagne publicitaire avec des influenceurs + nouveaux ambassadeurs",
  "Development of the Audit platform": "Développement de la plateforme d'audit",
  "Development of the Staking platform":
    "Développement de la plateforme de jalonnement",
  "Beta Launch Flash Transfer": "Lancement de la bêta Transfert Flash",
  "Flash x Binance": "Flash x Binance",
  "Launch Flash Wallet extension & application Android + IOS":
    "Lancer l'extension et l'application Flash Wallet Android + IOS",
  "AML compliance": "Conformité AML",
  "Flash x Synaps": "Flash x Synaps",
  "Flash x Certik": "Flash x Certik",
  "Launch Flash Transfer extension & application Android + IOS":
    "Launch of Flash Staking",
  2023: "2023",
  2024: "2024",

  "Flash 3.0": "Flash",
  "Flash Token": "FTT Token",
  "Flash token is a governance token tied to the entire Flash Technologies ecosystem. 2% of all revenues (transaction fees) will be reinvested in the token in the form of a monthly Buyback. Flash token is also linked to NFT Airdrops, where holders will receive 1% of the monthly token volume directly. The token is cross-chain, meaning it can be purchased on multiple networks with the same address. It can also be transferred to other wallets without paying transfer fees.":
    "Le FTT TOKEN est un Token de gouvernance lié à l'ensemble de l'écosystème de Flash  Technologies.\n\n 2 % des revenus sur une partie des services Flash seront réinvestis dans le Token sous la forme  de rachat mensuel (Buy Back). Voir le livre blanc \nNos répartitions sur les frais de transactions:\n1 % iront dans le marketing et le développement, 1 % iront dans la pool de staking et 1 % du  volume mensuel sera versé aux détenteurs des NFTS AIRDROPS. Voir la description NFT\n\n Le jeton est cross-chain, ce qui signifie qu'il peut être acheté sur différentes blockchains avec la  même adresse et il peut également être transféré vers d'autres portefeuilles sans frais de transfert.",
  "Flash is a cross-chain token that aims to become a means of exchange within the Flash Technologies ecosystem, which includes services such as Flash-transfer.com, Flash Wallet, and more, so that regular users are not exposed to exorbitant transaction fees. One of the primary goals of Flash is to provide holders with the ability to trade at a lower cost. Additionally, it will provide various benefits based on the number of tokens held by the user. ":
    "Flash est un Token cross-chain qui vise à devenir un moyen d'échange au sein de  l'écosystème Flash Technologies, qui comprend des services tels que Flash-transfer, Flash-Wallet  et plus encore, afin que les utilisateurs réguliers ne soient pas exposés à des frais de transaction  élevés. L'un des principaux objectifs de Flash est d'offrir aux détenteurs la possibilité d'échanger à moindre coût. En outre, il offrira divers avantages en fonction du nombre de Tokens détenus par l'utilisateur.",
  "Contract address": "Adresse du contrat",
  "Token name": "Nom du jeton",
  "Total Supply": "Approvisionnement total",
  Symbol: "Symbole",
  "Available on": "Disponible sur",
  "Audit Flash": "Audit Flash",
  Marketing: "de marketing",
  Liquidity: "Liquidité",
  Burn: "de brûlure",
  Rewards: "de récompenses",
  DAYS: "jours",
  HOURS: "heures",
  MINUTES: "minutes",
  SECONDS: "secondes",
  "Integration of dead wallet feature in Flash Wallet": "Integration of dead wallet feature in Flash Wallet",
  "Launch of the Launchpad": "Launch of the Launchpad",
  "Opening of our branch in France": "Opening of our branch in France",
  "New partner in money transfer": "New partner in money transfer",
  "Finalization of the Flash Transfer website": "Finalization of the Flash Transfer website",
  "Completion of the Flash Transfer application": "Completion of the Flash Transfer application",
  "Exchange listing": "Exchange listing",

  "Official Launch of Flash Transfer platform": "Official Launch of Flash Transfer platform",
  "Launch of the VIP Program for $FLASH Token Holders": "Launch of the VIP Program for $FLASH Token Holders",
  "Integration of Dead Wallet into Flash Wallet": "Integration of Dead Wallet into Flash Wallet",
  "Mass User Acquisition for Flash Transfer and Flash Wallet": "Mass User Acquisition for Flash Transfer and Flash Wallet",
  "Launch of a Comprehensive Marketing Strategy": "Launch of a Comprehensive Marketing Strategy",
  "Finalization of the Dead Wallet Website": "Finalization of the Dead Wallet Website",
  "Development of a API for the Dead Wallet": "Development of a API for the Dead Wallet",
  
  "Auditing Flash Transfer & Flash Wallet apps":
    "Audit des applications Flash-Transfer et Flash-Wallet",
  "Flash x Orange Money": "Flash x Orange Money",
  "Flash x MTN": "Flash x MTN",
  "Launch of Flash Payment": "Lancement de Flash Payment",
  "Launch of Dead Wallet": "Lancement de Dead Wallet",
  "Waiting for PSAN registration in France":
    "En attente d’enregistrement PSAN en France",
  "Waiting for VASP registration in UAE":
    "En attente d’enregistrement VASP aux UAE",
};
